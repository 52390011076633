$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function(){
        $(this).toggleClass("active");
        $(".js-navmobile").toggleClass("active");
    });
    
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function(){
        $(this).parents(".js-accordion-item").toggleClass("active").find(".js-accordion-content").slideToggle();
    });

    $(".js-accordiondownloads-button").click(function(){
        $(this).toggleClass("active").next(".js-accordiondownloads-content").slideToggle();
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * sliderCard
    // *
    // *
    var $sliderCard = $(".js-slidercard");

    if ($sliderCard.length) {
        $sliderCard.each(function () {
            $(this).slick({
                fade: false,
                dots: false,
                infinite: false,
                arrows: false,
                autoplay: false,
                speed: 1000,
                draggable: false,
                variableWidth: true,
                responsive: [
                    {
                      breakpoint: 1400,
                      settings: {
                        centerMode: true,
                        draggable: true,
                        dots: true,
                      }
                    }
                  ]
            });
        });
    }    
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * sliderImage
    // *
    // *
    var $sliderImage = $(".js-sliderimage");

    if ($sliderImage.length) {
        $sliderImage.each(function () {
            $(this).slick({
                fade: false,
                dots: false,
                infinite: true,
                autoplay: false,
                speed: 1000,
                centerMode: true,
                variableWidth: true,
                arrows: true,
                prevArrow: $(this).parents(".js-sliderimage-wrapper").find(".js-sliderimage-prev"),
                nextArrow: $(this).parents(".js-sliderimage-wrapper").find(".js-sliderimage-next"),
                responsive: [
                    {
                      breakpoint: 890,
                      settings: {
                        arrows: false,
                        dots: true,
                      }
                    }
                  ]
            });
        });
    }  
    
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * filter
    // *
    // *
    if ($(".js-filter-elements").length) {
        var pageLimit = $(".js-filter-elements").data("page-limit");

        var mixer = mixitup('.js-filter-elements', {
            selectors: {
                target: '.js-filter-element'
            },
            controls: {
                toggleDefault: 'none',
                live: true
            },
            pagination: {
                limit: pageLimit
            },
            animation: {
                enable: false
            },
            templates: {
                pager: '',
                pagerTruncationMarker: '',
                pagerPrev: '<button type="button" class="${classNames}" data-page="prev"><svg><use xlink:href="assets/templates/web/img/sprite.svg#angle-left"></use></svg></button>',
                pagerNext: '<button type="button" class="${classNames}" data-page="next"><svg><use xlink:href="assets/templates/web/img/sprite.svg#angle-right"></use></svg></button>'
            }
        });
    }

    $(".js-filter-button").click(function() {
        $(".js-filter-button").each(function() {
            $(this).removeClass("filled");
            var filter = $(this).attr("data-filter");
            $(this).removeAttr("data-filter"); 
            $(this).attr("data-toggle", filter); 
        })
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * form modal
    // *
    // *
    $(".js-formmodal-button").click(function() {
        $(this).siblings(".js-formmodal").addClass("active");
    })
    
    $(".js-formmodal-close").click(function() {
        $(this).parents(".js-formmodal").removeClass("active");
    })
});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function(){
    if($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    }else {
        $(".js-header").removeClass("sticky");
    }
});
